import Vue from 'vue'
import App from './App.vue'
import axiosApi from "axios"
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

const axios = axiosApi.create({
  baseURL: "https://presenciamanifiesta.org/",
  headers: {contentType: 'application/json'}
});

window.axios = axios;

window.onbeforeunload = function(evt) {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
