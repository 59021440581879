<template>
    <div class="season-message">
        <span class="hashtag">#PresenciaManifiesta</span>

        <div class="messages-container">
            <p>
                <!-- Aquí va el mensaje de temporada -->
            </p>
        </div>

        <button class="pbutton" @click="redirect('Us')">Acerca de nosotros</button>
    </div>
</template>

<script>
export default {
    methods: {
        redirect: function(r)
        {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.$router.push({name:r});
        }
    }
}
</script>

<style scoped>
.season-message {
    width: 100%;
    height: auto;
    background-color: var(--season-bg-color);
    color: var(--season-text-color);
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 5%;
    padding-right: 5%;
}

.messages-container, .messages-container > p, button {
    margin-top: 30px;
}

.hashtag {
    font-size: 1.5rem;
}
</style>
