<template>
    <div>
        <div class="relative-container">
            <UsCover />
        </div>

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" class="mav-top-diagonal" />
            <MaV style="margin-top:1px;" />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <FAQ />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" />
            <AskForPrayRequest />
        </div>

    </div>
</template>

<script>
import UsCover from '@/components/site/us/UsCover.vue'
import MaV from '@/components/site/us/MaV.vue'
import FAQ from '@/components/site/us/FAQ.vue'
import AskForPrayRequest from '@/components/site/praying/AskForPrayRequest.vue'
import BorderPolygonTop from '@/components/core/BorderPolygonTop.vue'
import BorderPolygonBottom from '@/components/core/BorderPolygonBottom.vue'

export default {
    components: {
        UsCover,
        MaV,
        AskForPrayRequest,
        FAQ,
        BorderPolygonTop,
        BorderPolygonBottom },
    data: function()
    {
        return {
            diagonal: true
        }
    }
}
</script>

<style scoped>
.mav-top-diagonal {
    z-index:3;
    margin-top: -8px !important;
}
</style>
