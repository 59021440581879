<template>
    <div>
        <div class="relative-container">
            <GivingCover />
        </div>

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" />
            <Decorative />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <WaysOfGiving />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" />
            <AskForPrayRequest />
        </div>

    </div>
</template>

<script>
import GivingCover from '@/components/site/giving/GivingCover.vue'
import Decorative from '@/components/site/giving/Decorative.vue'
import WaysOfGiving from '@/components/site/giving/WaysOfGiving.vue'
import AskForPrayRequest from '@/components/site/praying/AskForPrayRequest.vue'
import BorderPolygonTop from '@/components/core/BorderPolygonTop.vue'
import BorderPolygonBottom from '@/components/core/BorderPolygonBottom.vue'


export default {
    components: {
        GivingCover,
        Decorative,
        WaysOfGiving,
        AskForPrayRequest,
        BorderPolygonTop,
        BorderPolygonBottom 
    },
    data: function() {
        return {
            diagonal: true,
            pageTitle: "Dar",
            coverDescription: [
                "Nosotros conocemos que de Dios recibimos todo, desde nuestras fuerzas, el tiempo y la oportunidad en nuestra vida y nos da la oportunidad de honrarle con cada cosa que nos ha dado. Fuerzas, finanzas, talentos, dones, tiempo, etc. 1 crónicas 29 :14 “… ¡Todo lo que tenemos ha venido de ti, y te damos solo lo que tú primero nos diste! Y nos instruye en cuanto a nuestras decisiones económicas en 1 de Timoteo 6:18 Diles que usen su dinero para hacer el bien. Deberían ser ricos en buenas acciones, generosos con los que pasan necesidad y estar siempre dispuestos a compartir con otros. 19De esa manera, al hacer esto, acumularán su tesoro como un buen fundamento para el futuro, a fin de poder experimentar lo que es la vida verdadera. Y obedecemos su Palabra es por eso que todos damos de lo recibido."
            ]
        }
    }
}
</script>
