<template>
    <div>
        <Privacy />
    </div>
</template>

<script>
import Privacy from '@/components/site/Privacy.vue'

export default {
    components: { Privacy }
}
</script>