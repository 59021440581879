<template>
    <div class="ask-pr-container mx-auto">
        <div class="header-content mx-auto">
            <h1>¿Tienes alguna petición de oración?</h1>
            <h3>Haznos saber y oremos juntos.</h3>

            <ul class="buttons">
                <li><button class="pbutton" @click="redirect('oracion')">Oración</button></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'askForPrayingRequest',
    methods: {
        redirect: function(r)
        {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.$router.push({path:r});
        }
    }
}
</script>

<style scoped>
.ask-pr-container {
    width: 100%;
    height: 600px;

    background-image: url("../../../assets/img/oracion_4.jpg");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size:cover;
    
    display: flex;
    align-items: center;
    color: var(--accent-odark-color);

    padding-left: 30px;
    padding-right: 30px;
}

.header-content{
    z-index:2;
    align-items: center;
    text-align: center;
    margin-top: 150px;
}

.header-content h1{
    font-size:50px;
    margin-bottom:0;
}

.header-content p{
    font-size:1.5rem;
    display:block;
    padding-bottom:2rem;
}

.buttons {
    margin-top: 15px;
    padding-left: 0;
}

.buttons > li {
    display: inline-block;
}

.buttons > li > * {
    margin-right: 5px;
    margin-left: 5px;
}
</style>
