<template>
    <div class="giving-container">
        <h1><span class="section-title">Formas de dar</span></h1>

        <b-container>
            <b-row class="way-container" align-v="center">
                <b-col cols="12" md="5">
                    <b-container fluid>
                        <b-row align-h="center">
                            <b-icon-geo-alt class="give-icon"></b-icon-geo-alt>
                        </b-row>

                        <b-row align-h="center">
                            <span class="way-title">En sitio</span>
                        </b-row>
                    </b-container>
                </b-col>

                <b-col cols="12" md="7">
                    <div>
                        <p class="way-description">Puedes dejar tu diezmo u ofrenda en nuestras instalaciones, durante los horarios de reunión. Si tienes duda acerca de otros horarios en los que puedas dejar tu ofrenda, comunícate con nosotros para más información.</p>
                    </div>
                </b-col>
            </b-row>

            <b-row class="way-container" align-v="center">
                <b-col cols="12" md="7">
                    <div>
                        <p class="way-description">Puedes dar tu ofrenda mediante el uso de transferencias o depósitos bancarios en un banco <span class="number-title">Santander</span> con los siguientes datos.</p>
                        <p class="way-description">
                            <span class="number-title">Cuenta</span>
                            <br>65504345900
                        </p>
                        <p class="way-description">
                            <span class="number-title">CLABE Interbancaria</span>
                            <br>014840655043459002
                        </p>
                    </div>
                </b-col>

                <b-col cols="12" md="5">
                    <b-container fluid>
                        <b-row align-h="center">
                            <Bank class="give-icon" />
                        </b-row>

                        <b-row align-h="center">
                            <span class="way-title">Depósito bancario</span>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Bank from '@/components/core/Bank.vue'

export default {
    name: 'WaysOfGiving',
    components: { Bank },
    data: function()
    {
        return {

        }
    }
}
</script>

<style scoped>
.giving-container {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--main-bg-color);
}

.way-container {
    margin-top: 60px;
    margin-bottom: 30px;
}

.give-icon {
    width: auto;
    height: 160px !important;
    fill: var(--main-text-color);
}

.way-title {
    font-size: 2rem;
    margin-top: 10px;
}

.way-description {
    font-size: 1.1rem;
}

.number-title {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--accent-text-color);
}
</style>
