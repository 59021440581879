<template>
    <div>
        <PrayingCover />
        <Decorative />
    </div>
</template>

<script>
import PrayingCover from '@/components/site/praying/PrayingCover.vue'
import Decorative from '@/components/site/praying/Decorative.vue'

export default {
    components: { PrayingCover, Decorative }
}
</script>
