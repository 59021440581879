<template>
    <div class="question-container">
        <span class="question">{{ question }}</span>
        <p>
            <span v-for="item in answer" :key="item.value">
                <span class="text" v-if="item.type == 'text'">{{ item.value}} </span>
                <span class="cover-bible-addr" v-if="item.type == 'addr'">{{ item.value}} </span>
                <span class="cover-bible-quote" v-if="item.type == 'quote'">{{ item.value}} </span>
                <span v-if="item.type == 'jump'"><br></span>
            </span>
        </p>
    </div>
</template>

<script>
export default {
    name: 'questionTemplate',
    props: ['question', 'answer']
}
</script>

<style scoped>
*
{
    text-align: left;
}

.question-container {
    margin-top: 30px;
}

.question {
    font-size: 1.3rem;
}
</style>
