<template>
    <div id="app">
        <Header :transparentToSolid="transparentToSolidParam"
            :headerHeightFillColor="headerHeightFillColorParam"
            :headerMidDisbanish="headerMidDisbanishParam" class="header" />
        <router-view/>
        <Footer class="footer" />
    </div>
</template>

<script>
import Header from "./components/site/Header.vue"
import Footer from "./components/site/Footer.vue"

export default {
    name: "app",
    components: { Header, Footer },
    data: function()
    {
        return {
            transparentToSolidParam: false,
            headerHeightFillColorParam: 100,
            headerMidDisbanishParam: 99,
            headerStatus: {
                'default': {
                    'transparentToSolid': false,
                    'headerHeightFillColor': 450,
                    'headerMidDisbanish': 60
                },
                'home': {
                    'transparentToSolid': true,
                    'headerHeightFillColor': 450,
                    'headerMidDisbanish': 60
                },
                'online': {
                    'transparentToSolid': true,
                    'headerHeightFillColor': 450,
                    'headerMidDisbanish': 60
                }
            }
        }
    },
    created: function() {
        this.updateContent(this.$route.name)
    },
    computed : {
        page () {
            return this.$route.name
        }
    },
    watch : {
        page (val) {
            this.updateContent(val)
        }
    },
    methods:
    {
        helperObjectParam: function(obj)
        {
            this.transparentToSolidParam = (obj.transparentToSolid) ? obj.transparentToSolid : false;
            this.headerHeightFillColorParam = (obj.headerHeightFillColor) ? obj.headerHeightFillColor : 100;
            this.headerMidDisbanishParam = (obj.headerMidDisbanish) ? obj.headerMidDisbanish : 99;
        },
        updateContent: function(page)
        {
            if(page === "Home")
                this.helperObjectParam(this.headerStatus.home);
            else if(page === "Online")
                this.helperObjectParam(this.headerStatus.online);
            else
                this.helperObjectParam(this.headerStatus.default);
        }
    }
}
</script>

<style>
:root {
    /* Colors */
    --main-bg-color: #ffffff;
    --main-text-color: #3f3f3f;
    --sec-text-color: #2c2c2c;
    --accent-text-color: #2f5cd8;
    --season-bg-color: #152E56;
    --season-text-color: #ffffff;
    --location-bg-color: #152E56;
    --location-text-color: #3f3f3f;
    --mav-bg-color: #000000;
    --sn-bg-color: #152e56;

    --header-trans-menu-text-color: #e4e4e4;
    --accent-odark-color: #ffffff;
    --accent-olight-color: #323232;

    /* Sizes */
    --header-height: 80px;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.header {
    z-index: 5;
}

body {
    background-color: gray !important;
    max-width: 2000px;
}

.pbutton {
    padding-right: 20px !important;
    padding-left: 20px !important;
    border: 3px solid var(--accent-odark-color) !important;
    border-radius: 30px !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    font-size: 1.5rem;
    color: var(--accent-odark-color) !important;
    outline: none !important;
}

.pbutton:hover {
    background-color: var(--accent-odark-color) !important;
    opacity: 1;
    font-size: 1.5rem;
    color: var(--main-text-color) !important;
    outline: none !important;
}

.pbutton2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
    border: 3px solid var(--season-bg-color) !important;
    border-radius: 30px !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    font-size: 1.5rem;
    color: var(--season-bg-color) !important;
    outline: none !important;
}

.pbutton2:hover {
    background-color: var(--season-bg-color) !important;
    opacity: 1;
    font-size: 1.5rem;
    color: var(--accent-odark-color) !important;
    outline: none !important;
}

.container-c1 {
    min-height: 300px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
}

.section-title {
    font-size: 2rem;
    width: 100%;
}

.sub-sec-title {
    font-size: 1.5rem;
}

.sub-extra-title {
    font-size: 1rem;
}

.blured-container {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.cover-bible-quote {
    /* font-family: Helvetica; */
    font-size: 1rem;
    font-style: italic;
    color: var(--sec-text-color);
}

.cover-bible-addr {
    /* font-family: Helvetica; */
    font-size: 1rem;
    font-style: normal;
    color: var(--accent-text-color);
}

/* Portada de páginas */
.cover-container {
    width: 100%;
    padding-top: var(--header-height);
    padding-bottom: 60px;
    background-color: var(--main-bg-color);
}

.conver-content {
    width: 80%;
    margin-top: 60px;
    position: relative;
}

.page-cover-h1 {
    margin-bottom: 30px;
}

.paragraph {
    text-align: justify;
}

/* Diagonales */
.border-polygon {
    width: 100%;
    height: 7vh;
    position: absolute;
    left: 0;
    bottom: 0;
}

.border-polygon > * {
    fill:var(--main-bg-color);
}

.bp-top {
    top: 0 !important;
    margin-top: -1px !important;
}

.bp-bottom {
    bottom: 0 !important;
    margin-bottom: -1px !important;
}

.relative-container {
    width: 100%;
    height: auto;
    position: relative;
}

.cstm-blue-bp polygon {
    fill: var(--sn-bg-color) !important;
}

.dark-bg {
    background-color: var(--main-text-color);
}

@media not all and (min-resolution:.001dpcm) { 
    @media {
        .safari10, .safari102 { 
            background-attachment:scroll !important;
        }

        .safari101 {
            background-image: none !important;
        }

        .safari102 {
            background-position-y: 0 !important;
        }
    }
}
</style>
