<template>
    <div class="sn-container">
        <div>
            <h1><span>Redes Sociales</span></h1>
            <h2><span>Presencia Manifiesta</span></h2>
        </div>

        <b-container>
            <b-row>
                <b-col cols="12" md="4" class="sn-col-container">
                    <b-container>
                        <b-row class="logo-row-container">
                            <b-col cols="12">
                                <a target="_blank" class="sn-link" href="https://www.facebook.com/PresenciaManifiesta/"><FacebookLogo class="sn-logo sn-logo-sec" /></a>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <a target="_blank" class="sn-link sn-link-text" href="https://www.facebook.com/PresenciaManifiesta/"><span class="sn-name">Facebook</span></a>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>

                <b-col cols="12" md="4" class="sn-col-container">
                    <b-container>
                        <b-row class="logo-row-container">
                            <b-col cols="12">
                                <a target="_blank" class="sn-link" href="https://www.youtube.com/user/PresenciaManifiesta"><YouTubeLogo class="sn-logo sn-logo-main" /></a>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <a target="_blank" class="sn-link sn-link-text" href="https://www.youtube.com/user/PresenciaManifiesta"><span class="sn-name">YouTube</span></a>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>

                <b-col cols="12" md="4" class="sn-col-container">
                    <b-container>
                        <b-row class="logo-row-container">
                            <b-col cols="12">
                                <a target="_blank" class="sn-link" href="https://www.instagram.com/PresenciaManifiesta/"><InstagramLogo class="sn-logo sn-logo-sec" /></a>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <a target="_blank" class="sn-link sn-link-text" href="https://www.instagram.com/PresenciaManifiesta/"><span class="sn-name">Instagram</span></a>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
        <!-- <BorderPolygonBottom class="cstm-blue-bp" /> -->
    </div>
</template>

<script>
import YouTubeLogo from '@/components/core/YouTubeLogo.vue'
import FacebookLogo from '@/components/core/FacebookLogo.vue'
import InstagramLogo from '@/components/core/InstagramLogo.vue'

export default {
    name: 'social',
    components: { YouTubeLogo, FacebookLogo, InstagramLogo }
}
</script>

<style scoped>
.sn-container {
    width: 100%;
    position: relative;
    background-color: var(--sn-bg-color);
    padding-top: 50px;
    padding-bottom: 50px;
    color: white;
}

.logo-row-container {
    height: 200px;
    display: flex;
    align-items: center;
    /* background-color: red; */
}

.sn-col-container {
    padding-top: 60px;
    padding-bottom: 30px;
}

.sn-logo {
    fill: white;
}

.sn-logo-main {
    width: auto;
    height: 180px;
}

.sn-logo-sec {
    width: auto;
    height: 150px;
}

.sn-link, .sn-link:visited {
    color: white;
}

.sn-link:hover {
    opacity: 0.8;
}

.sn-name {
    padding-top: 30px;
    font-size: 1.8rem;
}

/* .cstm-bp-top {
    margin-top: 1px !important;
} */
</style>
