<template>
    <!-- Se utilizará un encabezado personalizado debido a que se buscan implementar
    características que Bootstrap Vue aun no permite implementar, o donde la implementación
    se vuelve más costosa que la versión personalizada. -->
    <div>
        <div class="header" v-bind:class="checkHeaderStyle">
            <b-container fluid>
                <b-row class="vert-container">
                    <b-col class="cell" cols="2">
                        <div>
                            <a href="/" class="d-none d-md-block">
                                <Flama class="logo_pc" />
                            </a>

                            <button v-b-toggle.sidebar class="vert-item d-md-none menu-button">
                                <Menu class="menu-cust-icon" />
                            </button>
                        </div>
                    </b-col>
                    <b-col>
                        <ul class="options vert-item mx-auto d-none d-md-inline-block">
                            <li><span class="menu-link" @click="navigate('en-linea')">En línea</span></li>
                            <li><span class="menu-link" @click="navigate('oracion')">Oración</span></li>
                            <li><span class="menu-link" @click="navigate('nosotros')">Nosotros</span></li>
                            <li><span class="menu-link" @click="navigate('dar')">Dar</span></li>
                        </ul>

                        <a href="/" class="d-md-none">
                            <Flama class="logo_pc" />
                        </a>
                    </b-col>
                    <b-col class="cell" cols="2"></b-col>
                </b-row>
            </b-container>
        </div>

        <b-sidebar id="sidebar" aria-labelledby="sidebar" right lazy shadow>
            <template>
                <div class="p-3">
                    <h4 id="sidebar-no-header-title">Menú</h4>
                    <!-- <p>
                        Cras mattis consectetur purus sit amet fermentum.
                    </p> -->
                    <br>
                    <nav class="mb-3">
                        <b-nav vertical>
                            <b-nav-item href="/">Inicio</b-nav-item>
                            <b-nav-item href="#/en-linea">En línea</b-nav-item>
                            <b-nav-item href="#/oracion">Oración</b-nav-item>
                            <b-nav-item href="#/nosotros">Nosotros</b-nav-item>
                            <b-nav-item href="#/dar">Dar</b-nav-item>
                        </b-nav>
                    </nav>
                </div>
            </template>

            <template v-slot:footer="{ hide }">
                <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                    <strong class="mr-auto">Presencia Manifiesta</strong>
                    <b-button size="sm" @click="hide">Cerrar menú</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>


<script>
import Flama from '../core/Flama.vue'
import Menu from '../core/Menu.vue'

export default {
    name: 'custom-header',
    components: { Flama, Menu },
    props: ['transparentToSolid', 'headerHeightFillColor', 'headerMidDisbanish'],
    data: function()
    {
        return {
            yAxis: 0,
            currentUsedHeaderStyle: 'header-top',
            oldUsedHeaderStyle: 'header-top',
            interval: false
        }
    },
    created: function() {
        // Variable que controla el scrolling para cambiar de color transparente a sólido
        if(!this.headerHeightFillColor) this.headerHeightFillColor = 450;
        if(!this.headerMidDisbanish) this.headerMidDisbanish = 60;

        if(this.transparentToSolid && this.transparentToSolid == true)
            window.addEventListener('scroll', this.scrollHandler);
    },
    computed: {
        checkHeaderStyle: function() {
            let style = 'header-body';

            if(this.transparentToSolid && this.transparentToSolid == true)
            {
                if(this.yAxis > this.headerMidDisbanish && this.yAxis < this.headerHeightFillColor)
                    style = 'header-disp';
                else if(this.yAxis < this.headerMidDisbanish)
                    style = 'header-top';
            }

            this.currentUsedHeaderStyle = style;
            return style;
        }
    },
    watch: {
        currentUsedHeaderStyle: function() {
            if(this.transparentToSolid && this.transparentToSolid == true)
            {
                clearInterval(this.interval);
                if(this.currentUsedHeaderStyle != this.oldUsedHeaderStyle)
                {
                    this.oldUsedHeaderStyle = this.currentUsedHeaderStyle;
                }
            }
        }
    },
    methods:
    {
        scrollHandler: function(event) {
            if(this.transparentToSolid && this.transparentToSolid == true)
            {
                let dis = window.scrollY;
                this.yAxis = dis;
            }
        },
        navigate: function(r) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.$router.push({path:r});
        }
    }
}
</script>

<style scoped>
.header, .vert-container {
    height: var(--header-height);
}

.header {
    width: 100%;
    position: fixed !important;
}

.header-top {
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.header-top .menu-link, 
.header-top .menu-link:visited, 
.header-top .logo_pc {
    color: var(--header-trans-menu-text-color);
}

.header-top .logo_pc, .header-top .menu-cust-icon {
    fill: var(--header-trans-menu-text-color);
}

.header-disp {
    background-color: transparent;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.header-disp .menu-link,
.header-disp .menu-link:visited,
.header-disp .logo_pc {
    color: var(--header-trans-menu-text-color);
}

.header-disp .logo_pc, .header-disp .menu-cust-icon {
    fill: var(--header-trans-menu-text-color);
}

.header-body {
    background-color: var(--main-bg-color);
    transition: all 0.5s ease-in-out;
}

/* Logo */
.logo_pc {
    height: 60px;
    margin-top: 10px;
}

/* Menu */
/* Vertical center */
.vert-container {
    position: relative;
}

.vert-item {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
}

.options {
    width: 100%;
}

.options > li {
    display: inline-block !important;
    /* display: ; */
    margin-left: 7px;
    margin-right: 7px;
}

.cell {
    text-align: right;
    padding-right: 0;
}

.menu-button {
    border: none;
    outline: none;
    background-color: transparent;
    margin-left:12px;
    /* z-index:10; */
}

.menu-cust-icon {
    height: 30px;
}

.menu-link {
    color: var(--main-text-color);
    font-size: 1.4rem;
    cursor: pointer;
}

.menu-link:hover {
    text-decoration: underline;
}

.menu-link:visited {
    color: var(--main-text-color);
}
</style>
