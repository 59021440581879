<template>
    <div class="broadcast-container">
        <span class="section-title" v-if="diagonal">Transmisión en vivo</span>
        <div class="title1" v-if="!diagonal">
            <span class="section-title">Transmisión en vivo</span>
        </div>

        <div class="broadcast-content-container">
            <!-- <div class="broadcast-img-blured"></div> -->

            <BorderPolygonTop v-if="diagonal" />

            <div class="video-container">
                <iframe src="https://www.youtube.com/embed/live_stream?channel=UCqzD20_PTaKhXoZBwJGsZEw" 
                    frameborder="0" allowfullscreen></iframe>
                <a class="yt-link" target="_blank"
                    href="https://www.youtube.com/user/PresenciaManifiesta">
                    <span>¿Te perdiste la transmisión en vivo? 
                        Mira nuestro canal <YouTubeLogo class="ytl" /> y no te pierdas ninguna transmisión.
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import YouTubeLogo from '@/components/core/YouTubeLogo.vue'
import BorderPolygonTop from '@/components/core/BorderPolygonTop.vue'
import BorderPolygonBottom from '@/components/core/BorderPolygonBottom.vue'

export default {
    name: 'broadcast',
    components: { YouTubeLogo, BorderPolygonTop, BorderPolygonBottom },
    props: ["diagonal"],
    mounted: function() {
        if(this.diagonal == undefined || this.diagonal == null) this.diagonal = false;
    }
}
</script>

<style scoped>
.broadcast-container {
    padding-top: 30px;
    background-color: var(--main-bg-color);
}

.broadcast-content-container{
    width: 100%;
    height: 700px;
    background-color: white;
    color: var(--accent-odark-color);
   
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url("../../../assets/img/enlinea_1.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;

    position: relative;
    margin-top: 30px;
}

.video-container {
    width: 50%;
    min-width: 300px;
    backdrop-filter: blur(0.8);
}

.video-container > iframe {
    width: 100%;
    height: 400px;
}

.video-container {
    text-align: left;
}

.title1 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.yt-link, .yt-link:visited {
    color: #ffffff;
    text-decoration: underline;
    font-size: 16px;
    font-size: 1rem;
}

.yt-link:hover {
    opacity: 0.8;
}

.ytl {
    height: 1rem;
    width: auto;
    fill: white;
}
</style>
