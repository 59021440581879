<template>
    <div class="faq-container">
        <div class="mx-auto">
            <h1><span class="section-title">Preguntas frecuentes</span></h1>

            <div class="questions">
                <div v-for="q in questions" :key="q.question">
                    <Question :question="q.question" :answer="q.answer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Question from './Question.vue'

export default {
    name: 'faq',
    components: { Question },
    data: function() 
    {
        return {
            questions: [
                {
                    'question': '¿Cuál es la directriz en nuestros comportamientos Y VIDA?',
                    'answer': [
                        {
                            type: 'text',
                            value: 'La Palabra de Dios nos lleva a tomar decisiones sabias cada día. '
                        },
                        {
                            type: 'addr',
                            value: 'Salmos 119:105 '
                        },
                        {
                            type: 'quote',
                            value: '“Tu palabra es una lámpara que guía mis pies y una luz para mi camino.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué tengo que asistir regularmente?',
                    'answer': [
                        {
                            type: 'text',
                            value: 'Porque somos un cuerpo y el cuerpo no debe estar separado, somos también una familia en la fe, las familias conviven, se apoyan y ayudan; además de que el congregarnos y convivir nos anima a vivir una constancia en la fe que tenemos en conjunto y a ser desafiados por las experiencias que otros tienen con Dios. ',
                        },
                        {
                            type: 'addr',
                            value: 'Gálatas 6:10 '
                        },
                        {
                            type: 'quote',
                            value: '“Por lo tanto, siempre que tengamos la oportunidad, hagamos el bien a todos, en especial a los de la familia de la fe.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué somos muchos?',
                    'answer': [
                        {
                            type: 'text',
                            value: 'Nunca seremos muchos, porque creemos que Dios tiene el deseo de alcanzar a cada individuo en esta tierra; por eso nos dejó la comisión de ir por todo el mundo y predicad el evangelio '
                        },
                        {
                            type: 'addr',
                            value: '(Mateo 28:19)'
                        },
                        {
                            type: 'text',
                            value: 'Nuestro deseo como iglesia es que todas las personas que nos rodean conozcan a Dios como su Salvador.'
                        }
                    ]
                },
                {
                    'question': '¿Por qué tenemos grupos pequeños donde nos conectamos contigo?',
                    'answer': 
                    [
                        {
                            type: 'text',
                            value: 'Creemos que Dios nos creó para vivir relacionándonos unos con otros y los grupos pequeños son el ambiente donde encontramos amigos, nos conectamos como familia y crecemos como personas en base a relaciones e intereses comunes.'
                        }
                    ]
                },
                {
                    'question': '¿Por qué valoramos la Presencia y Mensaje de Dios?',
                    'answer': [
                        {
                            type: 'addr',
                            value: 'Deuteronomio 4:29'
                        },
                        {
                            type: 'quote',
                            value: '“Sin embargo, desde allí, buscarán nuevamente al Señor su Dios. Y si lo buscan con todo el corazón y con toda el alma, lo encontrarán.”, '
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'addr',
                            value: 'Salmos 73:25 '
                        },
                        {
                            type: 'quote',
                            value: '“¿A quién tengo en el cielo sino a ti? Te deseo más que cualquier cosa en la tierra.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué decimos que SOMOS UNA IGLESIA DE INFLUENCIA?',
                    'answer': [
                        {
                            type: 'addr',
                            value: 'Mateo 5:13-16'
                        },
                        {
                            type: 'quote',
                            value: '“Ustedes son la sal de la tierra. Pero ¿para qué sirve la sal si ha perdido su sabor? ¿Pueden lograr que vuelva a ser salada? La descartarán y la pisotearán como algo que no tiene ningún valor. 14»Ustedes son la luz del mundo, como una ciudad en lo alto de una colina que no puede esconderse. 15»Nadie enciende una lámpara y luego la pone debajo de una canasta. En cambio, la coloca en un lugar alto donde ilumina a todos los que están en la casa. 16»De la misma manera, dejen que sus buenas acciones brillen a la vista de todos, para que todos alaben a su Padre celestial.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué TENEMOS UN COMPROMISO QUE SE OBSERVA EN UNA ENTREGA?',
                    'answer': 
                    [
                        {
                            type: 'addr',
                            value: 'Colosenses 3:23-25'
                        },
                        {
                            type: 'quote',
                            value: '“Trabajen de buena gana en todo lo que hagan, como si fuera para el Señor y no para la gente. 24»Recuerden que el Señor los recompensará con una herencia y que el Amo a quien sirven es Cristo; 25»pero si hacen lo que está mal, recibirán el pago por el mal que hayan hecho, porque Dios no tiene favoritos.”.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'addr',
                            value: '1 Samuel 12:24'
                        },
                        {
                            type: 'quote',
                            value: '“Por su parte, asegúrense de temer al Señor y de servirlo fielmente. Piensen en todas las cosas maravillosas que él ha hecho por ustedes.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué VIVIMOS CON PRODUCTIVIDAD?',
                    'answer':
                    [
                        {
                            type: 'addr',
                            value: 'Salmos 127:1'
                        },
                        {
                            type: 'quote',
                            value: '“Si el Señor no construye la casa, el trabajo de los constructores es una pérdida de tiempo. Si el Señor no protege la ciudad, protegerla con guardias no sirve para nada”.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'addr',
                            value: 'Marcos 4:8'
                        },
                        {
                            type: 'quote',
                            value: '“Pero otras semillas cayeron en tierra fértil, y germinaron y crecieron, ¡y produjeron una cosecha que fue treinta, sesenta y hasta cien veces más numerosa de lo que se había sembrado!.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué SOMOS UNA IGLESIA DESAFIANTE A TRAVÉS DE LA FE?',
                    'answer':
                    [
                        {
                            type: 'addr',
                            value: '1 Timoteo 6:12'
                        },
                        {
                            type: 'quote',
                            value: '“Pelea la buena batalla por la fe verdadera. Aférrate a la vida eterna a la que Dios te llamó y que declaraste tan bien delante de muchos testigos.”.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'addr',
                            value: '1 Juan 5:4'
                        },
                        {
                            type: 'quote',
                            value: 'Pues todo hijo de Dios vence a este mundo de maldad, y logramos esa victoria por medio de nuestra fe.”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué NOS GUSTA DISFRUTAR DE LA BONDAD DE DIOS?',
                    'answer':
                    [
                        {
                            type: 'addr',
                            value: 'Salmos 30:11'
                        },
                        {
                            type: 'quote',
                            value: '“Tú cambiaste mi duelo en alegre danza; me quitaste la ropa de luto y me vestiste de alegría,”.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'addr',
                            value: 'Romanos 8:32'
                        },
                        {
                            type: 'quote',
                            value: '“Si Dios no se guardó ni a su propio Hijo, sino que lo entregó por todos nosotros, ¿no nos dará también todo lo demás?”'
                        }
                    ]
                },
                {
                    'question': '¿Por qué hay musica?',
                    'answer':
                    [
                        {
                            type: 'text',
                            value: 'Es una forma de expresar la alabanza y adoración, al rendirle honra con instrumentos musicales y con cantos.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'quote',
                            value: '“David y todo el pueblo de Israel celebraban ante el SEÑOR, entonando canciones y tocando todo tipo de instrumentos musicales: liras, arpas, panderetas, castañuelas y címbalos.”.'
                        },
                        {
                            type: 'addr',
                            value: '‭‭2 Samuel‬ ‭6:5‬, Marcos 14:22-26'
                        }
                    ]
                },
                {
                    'question': '¿Por qué danzamos?',
                    'answer':
                    [
                        {
                            type: 'text',
                            value: 'La salvación una promesa inmensa, nos da la alegría a darle todo lo que somos.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'quote',
                            value: '“Alaba su nombre con danza, y acompáñala con panderetas y arpas,”'
                        },
                        {
                            type: 'addr',
                            value: '‭‭Salmos‬ ‭149:3'
                        }
                    ]
                },
                {
                    'question': '¿Por qué levantamos nuestras manos?',
                    'answer':
                    [
                        {
                            type: 'text',
                            value: 'Para darle a Dios la gloria, e indicar que Dios nos ha dado la victoria en toda circunstancia.'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'quote',
                            value: '“Levanten sus manos hacia el santuario, y alaben al SEÑOR.”.'
                        },
                        {
                            type: 'addr',
                            value: '‭‭Salmos‬ ‭134:2‬, Éxodo‬ ‭17:11‬'
                        }
                    ]
                },
                {
                    'question': '¿Por qué expresamos emociones durante el tiempo musical?',
                    'answer':
                    [
                        {
                            type: 'text',
                            value: 'Porque cuando adoramos a Dios integralmente, hay un quebrantamiento, que nuestras emociones no pueden resistir, pues Dios nos formo completos (espíritu, alma y cuerpo).'
                        },
                        {
                            type: 'jump',
                            value: '\n'
                        },
                        {
                            type: 'quote',
                            value: '“Ama al SEÑOR tu Dios con todo tu corazón, con toda tu alma, con toda tu mente y con todas tus fuerzas”.'
                        },
                        {
                            type: 'addr',
                            value: '‭‭Marcos‬ ‭12:30‬, Salmos‬ ‭51:8, ‭‭‭‭Salmos‬ ‭65:8‬, Josue 6:16'
                        }
                    ]
                }
                
            ]
        }
    }
}
</script>

<style scoped>
.faq-container {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: var(--main-bg-color);
}

.faq-container > div {
    width: 90%;
}

.questions {
    margin-top: 45px;
}
</style>
