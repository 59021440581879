<template>
    <!-- Basado en el código de Traversy Media
    https://www.youtube.com/watch?v=Xy3GlrddZFI -->

    <header class="v-header cont">
        <div class="video-container">
            <video autoplay="autoplay" muted="muted" loop="loop" crossOrigin playsinline preload="metadata" id="cover-video">
                <source src="@/assets/videos/video1.1.mp4" type="video/mp4">
            </video>
        </div>   

        <!-- <div class="header-overlay"></div> -->
        <div class="header-content mx-auto">
            <h1>Presencia Manifiesta</h1>
            <h3>Un lugar donde todo sucede</h3>

            <ul class="buttons">
                <li><button class="pbutton" @click="redirect('Online')">Online</button></li>
                <li><button class="pbutton" @click="redirect('Praying')">Oración</button></li>
            </ul>
        </div>
    </header>
</template>

<script>
export default {
    name: 'mainCover',
    methods: {
        redirect: function(r)
        {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.$router.push({name:r});
        }
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
}

.v-header {
    height: 101vh;
    display: flex;
    align-items: center;
    color: white;
}

.cont {
    max-width: 960px !important;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
    text-align: center;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 101vh;
    overflow: hidden;
}

.video-container video{
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, 0);
}

.header-overlay {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    background: #225470;
    opacity: 0.6;
}

.header-content{
    z-index:2;
    align-items: center;
    text-align: center;
}

.header-content h1{
    font-size:50px;
    margin-bottom:0;
}

.header-content p{
    font-size:1.5rem;
    display:block;
    padding-bottom:2rem;
}

.buttons {
    margin-top: 15px;
    padding-left: 0;
}

.buttons > li {
    display: inline-block;
}

.buttons > li > * {
    margin-right: 5px;
    margin-left: 5px;
}
</style>
