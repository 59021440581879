import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Online from '../views/Online.vue'
import Us from '../views/Us.vue'
import Praying from '../views/Praying.vue'
import Give from '../views/Give.vue'
import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

    const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/en-linea',
        name: 'Online',
        component: Online
    },
    {
        path: '/nosotros',
        name: 'Us',
        component: Us
    },
    {
        path: '/oracion',
        name: 'Praying',
        component: Praying
    },
    {
        path: '/dar',
        name: 'Give',
        component: Give
    },
    {
        path: '/privacidad',
        name: 'Privacy',
        component: Privacy
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
