<template>
    <div class="footer-container">
        <div class="footer-subcontainer mx-auto">
            <b-container fluid>
                <b-row>
                    <b-col cols="12" md="5">
                        <b-container class="footer-fimg-container" fluid>
                            <b-row>
                                <b-col>
                                    <a href="/"><img src="@/assets/logos/logo_pm_negro.png" alt="Presencia Manifiesta"></a>
                                </b-col>
                            </b-row>

                            <b-row class="last-text">
                                <b-col cols="12" md="7">
                                    <span>Presencia Manifiesta A.C. {{ getCurrentYear }}</span>
                                </b-col>

                                <b-col cols="12" md="5">
                                    <span class="footer-link" @click="navigate('Privacy')">Aviso de privacidad</span>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>

                    <b-col cols="12" md="7">
                        <b-container fluid>
                            <b-row>
                                <b-col class="link-column" cols="12" md="4" lg="2">
                                    <span class="footer-link-title"><a href="/">Sitio</a></span>

                                    <ul class="link-container">
                                        <li class="footer-link"><span @click="navigate('Home')">Inicio</span></li>
                                        <li class="footer-link"><span @click="navigate('Online')">En línea</span></li>
                                        <li class="footer-link"><span @click="navigate('Praying')">Oración</span></li>
                                        <li class="footer-link"><span @click="navigate('Us')">Nosotros</span></li>
                                        <!-- <li class="footer-link"><a href="/#horarios">Horarios</a></li>
                                        <li class="footer-link"><a href="/#ubicacion-contacto">Ubicación y contacto</a></li> -->
                                        <li class="footer-link"><span @click="navigate('Give')">Dar</span></li>
                                    </ul>
                                </b-col>

                                <b-col class="link-column" cols="12" md="4" lg="3">
                                    <span class="footer-link-title"><a href="en-linea">En línea</a></span>

                                    <ul class="link-container">
                                        <li class="footer-link"><a target="_blank" href="https://www.youtube.com/presenciamanifiesta">YouTube</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://www.facebook.com/PresenciaManifiesta/">Facebook</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://www.instagram.com/PresenciaManifiesta/">Instagram</a></li>
                                    </ul>
                                </b-col>

                                <!-- <b-col class="link-column" cols="12" md="4" lg="7">
                                    <span class="footer-link-title"><a href="#">Canales</a></span>

                                    <ul class="link-container">
                                        <li class="footer-link"><a target="_blank" href="https://youtube.com/playlist?list=PL0u6mHLDR0bHdILhy1VX5XTNNIHvGPixh">Manifiesta Music</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://youtube.com/playlist?list=PL0u6mHLDR0bGbM7MKFP75aI8d5BDZUuaN">Conexión</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://www.youtube.com/ManifiestaKids">Manifiesta Kids</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://www.youtube.com/channel/UCcv20Yug8XOsDoC9magwBpg">FireRocks</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://youtube.com/playlist?list=PL0u6mHLDR0bH-b-Uovd1nu-woJaZCozeY">Evidencias</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://youtube.com/playlist?list=PL0u6mHLDR0bHBJFO-ur32WZ6UMuhnPU3B">Reset</a></li>
                                        <li class="footer-link"><a target="_blank" href="https://www.youtube.com/channel/UCttrdxtlvB571Q_bmwEkBLA">Conexión Familiar</a></li>
                                    </ul>
                                </b-col> -->
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
export default {
    name: 'custom-footer',
    computed: {
        getCurrentYear: function() {
            return (new Date()).getFullYear();
        }
    },
    methods: 
    {
        navigate: function(r)
        {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.$router.push({name:r});
        }
    }
}
</script>

<style scoped>
.footer-container {
    background-color: var(--main-bg-color);
    padding-top: 30px;
    padding-bottom: 20px;
}

.link-column {
    text-align: left;
}

.footer-link-title {
    text-align: left;
    font-size: 1.5rem;
}

.footer-link-title > a, .footer-link-title > a:visited {
    color: inherit;
}

.link-container {
    padding-left: 0;
    text-align: left;
    list-style: none;
}

.footer-link, .footer-link > a {
    color: var(--main-text-color);
    margin: 0 !important;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-link > a:visited {
    color: var(--main-text-color);
}

.footer-fimg-container {
    text-align: left;
    margin-top: 40px;
}

.last-text {
    max-width: 450px;
    margin-top:40px;
    margin-bottom:20px;
}
</style>
