<template>
    <div id="horarios" class="service-times-container safari101 container-c1">
        <div class="service-times-subcontainer mx-auto">
            <h1><span class="section-title">Horarios</span></h1>
            <span class="sub-extra-title">Presencial y en línea</span>

            <b-container fluid>
                <b-row>
                    <b-col cols="12" md="6">
                        <div class="service-container">
                            <b-icon-people class="icon"></b-icon-people>
                            <h2><span class="sub-sec-title">Reuniones generales</span></h2>
                            <p class="schedule"><span class="service-day">Domingo</span><br>10:00 am<br>12:30 pm</p>
                        </div>
                    </b-col>

                    <b-col cols="12" md="6">
                        <div class="service-container">
                            <b-icon-book class="icon"></b-icon-book>
                            <h2><span class="sub-sec-title">Reunión general</span></h2>
                            <p class="schedule"><span class="service-day">Miércoles</span><br>8:00 pm</p>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <div class="additionalInfo">
                <span>Todas nuestras reuniones son transmitidas a través de nuestro 
                    <a href="https://www.youtube.com/presenciamanifiesta" target="_blank">canal de YouTube</a>.
                </span>
            </div>
            <button class="pbutton2" @click="redirect('Online')">Ver más</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mainCover',
    methods: {
        redirect: function(r)
        {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.$router.push({name:r});
        }
    }
}
</script>

<style scoped>
.service-times-container {
    background-image: url("../../../assets/logos/flama_gris_claro.png");
    background-blend-mode: darken;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30%;
    background-position-x: center;
    background-position-y: center;
}

.service-times-subcontainer {
    max-width: 1000px;
}

.icon {
    font-size: 80px;
}

.schedule {
    font-size: 1.2rem;
}

.service-day {
    font-weight: bold;
    font-size: 1rem;
}

.additionalInfo {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
