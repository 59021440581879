<template>
    <div>
        <div class="relative-container">
            <OnlineCover />
            <BorderPolygonBottom v-if="diagonal" />
        </div>
        
        <div class="relative-container">
            <!-- El borde se encuentra dentro del componente -->
            <Broadcast :diagonal="diagonal" />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" />
            <!-- <Channels /> -->
            <BorderPolygonBottom v-if="diagonal" class="cstm-blue-bp" />
        </div>

        <SocialNetworks />

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" class="cstm-blue-bp" />
            <AskForPrayRequest />
        </div>
    </div>
</template>

<script>
import OnlineCover from '@/components/site/online/OnlineCover.vue'
import Broadcast from '@/components/site/online/Broadcast.vue'
import SocialNetworks from '@/components/site/online/SocialNetworks.vue'
import AskForPrayRequest from '@/components/site/praying/AskForPrayRequest.vue'
import BorderPolygonTop from '@/components/core/BorderPolygonTop.vue'
import BorderPolygonBottom from '@/components/core/BorderPolygonBottom.vue'

export default {
    components: { 
        OnlineCover,
        Broadcast,
        SocialNetworks,
        AskForPrayRequest,
        BorderPolygonTop,
        BorderPolygonBottom
    },
    data: function()
    {
        return {
            diagonal: true
        }
    }
}
</script>
