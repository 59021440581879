<template>
    <div class="content">
        <div class="relative-container">
            <MainCover />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <WelcomeMessage />
        </div>

        <div class="relative-container">
            <BorderPolygonTop v-if="diagonal" />
            <SeasonMessage />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <ServiceTimes />
            <BorderPolygonBottom v-if="diagonal" />
        </div>

        <div class="relative-container">
            <LocationContact />
            <BorderPolygonBottom v-if="diagonal" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import MainCover from "@/components/site/main/MainCover.vue"
import WelcomeMessage from "@/components/site/main/WelcomeMessage.vue"
import SeasonMessage from "@/components/site/main/SeasonMessage.vue"
import ServiceTimes from "@/components/site/main/ServiceTimes.vue"
import LocationContact from "@/components/site/main/LocationContact.vue"
import BorderPolygonTop from '@/components/core/BorderPolygonTop.vue'
import BorderPolygonBottom from '@/components/core/BorderPolygonBottom.vue'

export default {
    name: 'inicio',
    components: {
        MainCover,
        WelcomeMessage,
        SeasonMessage,
        ServiceTimes,
        LocationContact,
        BorderPolygonTop,
        BorderPolygonBottom
    },
    data: function()
    {
        return {
            diagonal: false
        }
    }
}
</script>

<style scoped>
.content {
    width: 100%;
    min-height: 1500px;
    /* background-color: red !important; */
}
</style>
